import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout/';
import Seo from '../components/Seo/';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <section className="not-found">
      <div className="wrapper">
        <h1>Not Found</h1>
        <p>What you are looking for does not exist.</p>
        <Link to="/" className="cta cta--primary">Go back to Homepage</Link>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
